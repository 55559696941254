import { alexaTheme } from '@amzn/monera-app-alexa-shared-libs/lib/theme';
import { createDetailPage } from '@amzn/monera-app/lib/pages/dp/[asin]';

import { componentsById } from '../../services/get-static-component-map';

const DetailPage = createDetailPage({
  tenantTheme: alexaTheme,
  componentsMap: componentsById
});

export default DetailPage;
