
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/dp/[asin]",
      function () {
        return require("private-next-pages/dp/[asin].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/dp/[asin]"])
      });
    }
  